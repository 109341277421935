import axios from 'axios';
import { API_URL, TOKEN, CRM_APP_TOKEN } from "config";
import notify from 'utils/notifications';

const httpClient = axios.create({
    baseURL: API_URL,
});

httpClient.interceptors.request.use( (config) => {
    const token = localStorage.getItem(TOKEN);
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
}, (error) => {
    return Promise.reject(error); 
});

httpClient.interceptors.response.use( (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if(response.data.message){
        notify(response.data.type || 'success', response.data.message);
    }
    return response;
  }, (error) => {
    if(error.response.data.statusCode === 401){
        window.localStorage.removeItem(CRM_APP_TOKEN);
        window.localStorage.removeItem(TOKEN);
        window.location.href = '/';
    }else if(error.response.data.statusCode === 400){
        if(error.response.data.errors) {
            notify('error', error.response.data.errors[0]);
        }
    } else if(error.response.data.message){
        notify(error.response.data.type || 'error', error.response.data.message);
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

// Para hacer peticiones sin los interceptores(como en el login)

export default httpClient;
