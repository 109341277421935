import {createContext, useContext, useMemo, useEffect, useCallback, useState} from "react";
import { defaultSocket } from 'socket/socketHelper';

export const KanbanSocketContext = createContext();
export function KanbanSocketContextProvider({ children }) {
  const [currenntKanban, setCurrenntKanban] = useState({});
  const [kanbanVersion, setKanbanVersion] = useState({version: 1})
  let socket = defaultSocket;
  const connectToSocket = useCallback(() => {
    // setChats([]);
    // setMessages([]);
    socket.disconnect();
    socket.tryConnect();
  }, [socket])

  const updateVersion = () =>
    setKanbanVersion(({oldVersion}) => ({version: oldVersion + 1}))

  // ---
  const onCreateColumn = (data) => updateVersion();
  const onUpdateColumn = (data) => updateVersion();
  const onDeleteColumn = (data) => updateVersion();
  const onCreateItem = (data) => updateVersion();
  const onUpdateItemOrder = (data) => updateVersion();
  const onUpdateItem = (data) => updateVersion();
  const onDeleteItem = (data) => updateVersion();
  const onCreateComment = (data) => updateVersion();
  const onDeleteComment = (data) => updateVersion();
  const onUpdateColumnOrder = (data) => updateVersion();


  const openKanban = useCallback((kanbanId) => {
    if (currenntKanban)
      socket.disconnectFromKanban(currenntKanban);
    setCurrenntKanban(kanbanId);
    socket.connectToKanban(kanbanId);
  }, [])
  // ---

  useEffect(
    () => {
      console.log("Conectandose al socket")
      socket.disconnect();
      socket.tryConnect();
      socket.on('onCreateColumn', onCreateColumn)
      socket.on('onUpdateColumn', onUpdateColumn)
      socket.on('onDeleteColumn', onDeleteColumn)
      socket.on('onCreateItem', onCreateItem)
      socket.on('onUpdateItemOrder', onUpdateItemOrder)
      socket.on('onUpdateItem', onUpdateItem)
      socket.on('onDeleteItem', onDeleteItem)
      socket.on('onCreateComment', onCreateComment)
      socket.on('onDeleteComment', onDeleteComment)
      socket.on('onUpdateColumnOrder', onUpdateColumnOrder)
      return () => {
        socket.off('onCreateColumn')
        socket.off('onUpdateColumn')
        socket.off('onDeleteColumn')
        socket.off('onCreateItem')
        socket.off('onUpdateItemOrder')
        socket.off('onUpdateItem')
        socket.off('onDeleteItem')
        socket.off('onCreateComment')
        socket.off('onDeleteComment')
        socket.off('onUpdateColumnOrder')
      }
    },
    []);
  const value = useMemo(
    () => ({
      currenntKanban,
      kanbanVersion,
      // Utileria
      openKanban
    }),
    [currenntKanban, kanbanVersion, openKanban]
  );
  return <KanbanSocketContext.Provider value={value}>{children}</KanbanSocketContext.Provider>;
}

export function useSocketContext() {
  return useContext(KanbanSocketContext);
}
