import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { openSnackbar } from 'store/slices/snackbar';
import { dispatch } from 'store';

const MySwal = withReactContent(Swal);

export default (type, title) => {
  dispatch(
    openSnackbar({
        open: true,
        message: title,
        variant: 'alert',
        alert: {
            color: type
        },
        close: false
    })
);
  // MySwal.fire({
  //     //position: 'top-end',
  //     icon: type || 'success' ,
  //     title: title,
  //     showConfirmButton: false,
  //     timer: 1500
  // });
}