import * as React from 'react';
import { updateTown, validateUpdate } from '../services/ciudades.services';
import notify from 'utils/notifications';
import {
  Autocomplete,
  Button,
  Grid,
  Stack,
  TextField,
  Drawer,
  Box, 
  Divider, 
  Typography
} from '@mui/material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { gridSpacing } from 'store/constant';
import { getStates } from 'store/slices/states';
import { useDispatch, useSelector } from 'store';

export default function Edit({ open, setOpen, refresh, id, data }) {
  const dispatch = useDispatch();
  const { states } = useSelector((state) => state.states);
  const [name, setName] = React.useState('');
  const [code, setCode] = React.useState('');
  const [state, setState] = React.useState(null);

  React.useEffect(()=>{
    dispatch(getStates());
  },[]);

  React.useEffect(()=>{
    if(data){
      setName(data.name);
      setCode(data.code);
      setState(data.state);
    }
  },[id]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validation = validateUpdate(code, name, state ? state._id : '');
    if(!validation.success)
      return notify('error',validation.message);
    updateTown(id, { code, name, state: state._id }).then(() => {
      handleClose();
      refresh();
      setOpen(false);
    });
  };

  return (
    <>
      <Drawer
          sx={{
              ml: open ? 3 : 0,
              flexShrink: 0,
              zIndex: 1200,
              overflowX: 'hidden',
              width: { xs: 320, md: 450 },
              '& .MuiDrawer-paper': {
                  height: '100vh',
                  width: { xs: 320, md: 450 },
                  position: 'fixed',
                  border: 'none',
                  borderRadius: '0px'
              }
          }}
          variant="temporary"
          anchor="right"
          open={open}
          ModalProps={{ keepMounted: true }}
          onClose={handleClose}
      >
          {open && (
              <>
              <Box sx={{ p: 3 }}>
                  <Grid container alignItems="center" spacing={0.5} justifyContent="space-between">
                      <Grid item sx={{ width: 'calc(100% - 50px)' }}>
                          <Stack direction="row" spacing={0.5} alignItems="center">
                              <Button
                                  variant="text"
                                  color="error"
                                  sx={{ p: 0.5, minWidth: 32, display: { xs: 'block', md: 'none' } }}
                                  onClick={handleClose}
                              >
                                  <HighlightOffIcon />
                              </Button>
                              <Typography
                                  variant="h4"
                                  sx={{
                                      display: 'inline-block',
                                      width: 'calc(100% - 34px)',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      verticalAlign: 'middle'
                                  }}
                              >
                                  Editar ciudad
                              </Typography>
                          </Stack>
                      </Grid>
                  </Grid>
              </Box>
              <Divider />
              <PerfectScrollbar component="div">
                <Box sx={{ p: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={gridSpacing}>
                              <Grid item xs={12}>
                                <TextField
                                  autoFocus
                                  margin="dense"
                                  label="Código"
                                  type="text"
                                  value={code}
                                  onChange={(e)=>setCode(e.target.value)}
                                  fullWidth
                                  // variant="standard"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  margin="dense"
                                  label="Nombre"
                                  type="text"
                                  value={name}
                                  onChange={(e)=>setName(e.target.value)}
                                  fullWidth
                                  // variant="standard"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  options={states}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option._id}
                                  value={state}
                                  onChange={(e, value) => setState(value)}
                                  renderInput={(params) => <TextField {...params} placeholder='Estado'/>}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                  <Stack direction="row" spacing={1} justifyContent="flex-end">
                                      <Button color="error" onClick={handleClose}>
                                          Cancelar
                                      </Button>
                                      <AnimateButton>
                                          <Button variant="contained" type="submit">
                                              Guardar
                                          </Button>
                                      </AnimateButton>
                                  </Stack>
                              </Grid>
                            </Grid>
                        </form>     
                        </Grid>
                    </Grid>
                </Box>
                  </PerfectScrollbar>
                </>
          )}
      </Drawer>
    </>
  );
}
