import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

import Notificaciones from '../views/Notificaciones/index';
import Bot from '../views/Bot/index';

import { DASHBOARD_PATH } from 'configuration';
import { DASHBOARD, ANALYTICS, AGENTES, CONTACTOS, CONVERSACIONES, EMPRESAS, LOGOUT, SUCURSALES, USUARIOS, ESTADOS, CIUDADES, ROLES, PERMISOS } from 'config/routers/paths';
import Analytics from 'views/Analytics';
import Cuidades from 'views/Ciudades';

const Dashboard = Loadable(lazy(() => import('views/Dashboard/index')));
const Logout = Loadable(lazy(() => import('views/Logout')));
const Contactos = Loadable(lazy(() => import('views/Contactos/index')));
const Usuarios = Loadable(lazy(() => import('views/Usuarios/index')));
const Roles = Loadable(lazy(() => import('views/Roles/index')));
const Permisos = Loadable(lazy(() => import('views/Permisos/index')));
const Empresas = Loadable(lazy(() => import('views/Empresas/index')));
const Conversaciones = Loadable(lazy(() => import('views/Conversaciones/index')));
const Estados = Loadable(lazy(() => import('views/Estados/index')));
const Sucursales = Loadable(lazy(() => import('views/Sucursales/index')));
const AppKanban = Loadable(lazy(() => import('views/Kanban')));
const AppKanbanBacklogs = Loadable(lazy(() => import('views/Kanban/Backlogs')));
const AppKanbanBoard = Loadable(lazy(() => import('views/Kanban/Board')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: DASHBOARD,
            element: <Dashboard />
        },
        // {
        //     path: ANALYTICS,
        //     element: <Analytics />
        // },
        {
            path: CONTACTOS,
            element: <Contactos />
        },
        {
            path: USUARIOS,
            element: <Usuarios />
        },
        {
            path: ROLES,
            element: <Roles />
        },
        {
            path: EMPRESAS,
            element: <Empresas />
        },
        {
            path: CONVERSACIONES,
            element: <Conversaciones />
        },
        {
            path: ESTADOS,
            element: <Estados />
        },
        {
            path: CIUDADES,
            element: <Cuidades />
        },
        {
            path: SUCURSALES,
            element: <Sucursales />
        },
        {
            path: PERMISOS,
            element: <Permisos />
        },
        {
            path: '/Kanban',
            element: <AppKanban />,
            children: [
                {
                    path: 'backlogs',
                    element: <AppKanbanBacklogs />
                },
                {
                    path: 'board/:kanbanId',
                    element: <AppKanbanBoard />
                }
            ]
        },
        {
            path: LOGOUT,
            element: <Logout />
        },
    ]
};

export default MainRoutes;
