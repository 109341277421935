// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import request from 'utils/request';
import { ROLES } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    roles: [],
    role: null
};

const slice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getRolesSuccess(state, action) {
            state.roles = action.payload;
        },
        
        // GET CONTACT
        getRoleSuccess(state, action) {
            state.role = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRoles() {
    return async () => {
        const response = await request().get(ROLES.LISTAR);
        dispatch(slice.actions.getRolesSuccess(response.data.data));
    };
}

export function saveRole(role) {
    return async () => {
        await request().post(ROLES.GUARDAR, role);
    };
}

export function updateRole(id, role) {
    return async () => {
        await request().put(ROLES.ACTUALIZAR(id), role);
    };
}

export function deleteRole(id) {
    return async () => {
        await request().delete(ROLES.ELIMINAR(id));
    };
}

export function getRole(id) {
    return async () => {
        await request().get(ROLES.OBTENER(id));
    };
}
