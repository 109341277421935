// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc, IconBuilding, IconBuildingEstate, IconUsers, IconUserPlus } from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuilding,
    IconBuildingEstate,
    IconUsers,
    IconUserPlus
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application = {
    id: 'application',
    title: <FormattedMessage id="application" />,
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            url: '/usuarios',
            type: 'item',
            icon: icons.IconUserCheck,
        },
        {
            id: 'roles',
            title: <FormattedMessage id="roles" />,
            url: '/roles',
            type: 'item',
            icon: icons.IconUsers,
        },
        {
            id: 'roles',
            title: <FormattedMessage id="permisos" />,
            url: '/permisos',
            type: 'item',
            icon: icons.IconUserPlus,
        },
        {
            id: 'contacts',
            title: <FormattedMessage id="contact" />,
            url: '/contactos',
            icon: icons.IconNfc,
            type: 'item',
        },
        {
            id: 'companies',
            title: <FormattedMessage id="companies" />,
            url: '/empresas',
            icon: icons.IconBuilding,
            type: 'item',
        },
        {
            id: 'branches',
            title: <FormattedMessage id="branches" />,
            url: '/sucursales',
            icon: icons.IconBuildingEstate,
            type: 'item',
        },
        {
            id: 'states',
            title: <FormattedMessage id="states" />,
            url: '/estados',
            icon: icons.IconBuildingEstate,
            type: 'item',
        },
        {
            id: 'towns',
            title: <FormattedMessage id="towns" />,
            url: '/ciudades',
            icon: icons.IconBuildingEstate,
            type: 'item',
        },
        {
            id: 'chat',
            title: <FormattedMessage id="chat" />,
            type: 'item',
            icon: icons.IconMessages,
            url: '/conversaciones'
        },
        {
            id: 'kanban',
            title: 'Prospecciones',
            type: 'item',
            icon: icons.IconLayoutKanban,
            url: '/kanban'
        },
        // Inicio
        // Contactos
        // Empresas
        // Conversaciones
        // Notificaciones
        // Sucursales
        // Agentes
        // Usuarios
        // Reportes
        // Bot
        // Integraciones
        // {
        //     id: 'customer',
        //     title: <FormattedMessage id="customer" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'customer-list',
        //             title: <FormattedMessage id="customer-list" />,
        //             type: 'item',
        //             url: '/customer/customer-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'order-list',
        //             title: <FormattedMessage id="order-list" />,
        //             type: 'item',
        //             url: '/customer/order-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'create-invoice',
        //             title: <FormattedMessage id="create-invoice" />,
        //             type: 'item',
        //             url: '/customer/create-invoice',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'order-details',
        //             title: <FormattedMessage id="order-details" />,
        //             type: 'item',
        //             url: '/customer/order-details'
        //         },
        //         {
        //             id: 'product',
        //             title: <FormattedMessage id="product" />,
        //             type: 'item',
        //             url: '/customer/product',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-review',
        //             title: <FormattedMessage id="product-review" />,
        //             type: 'item',
        //             url: '/customer/product-review',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
       
        // {
        //     id: 'mail',
        //     title: <FormattedMessage id="mail" />,
        //     type: 'item',
        //     icon: icons.IconMail,
        //     url: '/app/mail'
        // },
        // {
        //     id: 'calendar',
        //     title: <FormattedMessage id="calendar" />,
        //     type: 'item',
        //     url: '/app/calendar',
        //     icon: icons.IconCalendar,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'contact',
        //     title: <FormattedMessage id="contact" />,
        //     type: 'collapse',
        //     icon: icons.IconNfc,
        //     children: [
        //         {
        //             id: 'c-card',
        //             title: <FormattedMessage id="cards" />,
        //             type: 'item',
        //             url: '/app/contact/c-card',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'c-list',
        //             title: <FormattedMessage id="list" />,
        //             type: 'item',
        //             url: '/app/contact/c-list',
        //             breadcrumbs: false
        //         }
        //     ]
        // },
        // {
        //     id: 'e-commerce',
        //     title: <FormattedMessage id="e-commerce" />,
        //     type: 'collapse',
        //     icon: icons.IconBasket,
        //     children: [
        //         {
        //             id: 'products',
        //             title: <FormattedMessage id="products" />,
        //             type: 'item',
        //             url: '/e-commerce/products'
        //         },
        //         {
        //             id: 'product-details',
        //             title: <FormattedMessage id="product-details" />,
        //             type: 'item',
        //             url: '/e-commerce/product-details/1',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'product-list',
        //             title: <FormattedMessage id="product-list" />,
        //             type: 'item',
        //             url: '/e-commerce/product-list',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'checkout',
        //             title: <FormattedMessage id="checkout" />,
        //             type: 'item',
        //             url: '/e-commerce/checkout'
        //         }
        //     ]
        // }
    ]
};

export default application;
