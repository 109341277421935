import { AppContextProvider } from './context/appContext';
import { SocketContextProvider } from './context/socketContext';
import { KanbanSocketContextProvider } from "./context/KanbanSocketContext";

import ThemeCustomization from 'themes';
import RTLLayout from 'ui-component/RTLLayout';
import Notistack from 'ui-component/third-party/Notistack';
import NavigationScroll from 'layout/NavigationScroll';
import Routes from 'routes';
import Snackbar from 'ui-component/extended/Snackbar';
import Locales from 'ui-component/Locales';

function App() {
  return (
    <ThemeCustomization>
        <RTLLayout>
          <Locales>
            <NavigationScroll>
              <AppContextProvider>
                <SocketContextProvider>
                  <KanbanSocketContextProvider>
                    <>
                        <Notistack>
                            <Routes />
                            <Snackbar />
                        </Notistack>
                    </>
                  </KanbanSocketContextProvider>
                </SocketContextProvider>
              </AppContextProvider>
            </NavigationScroll>
          </Locales>
        </RTLLayout>
    </ThemeCustomization>
  );
}

export default App;