// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import request from 'utils/request';
import { SUCURSALES } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    branches: [],
    branch: null
};

const slice = createSlice({
    name: 'branches',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getBranchesSuccess(state, action) {
            state.branches = action.payload;
        },
        
        // GET CONTACT
        getBranchSuccess(state, action) {
            state.branch = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBranches() {
    return async () => {
        const response = await request().get(SUCURSALES.LISTAR);
        dispatch(slice.actions.getBranchesSuccess(response.data.data));
    };
}

export function saveBranch(branch) {
    return async () => {
        await request().post(SUCURSALES.GUARDAR, branch);
    };
}

export function updateBranch(id, branch) {
    return async () => {
        await request().put(SUCURSALES.ACTUALIZAR(id), branch);
    };
}

export function deleteBranch(id) {
    return async () => {
        await request().delete(SUCURSALES.ELIMINAR(id));
    };
}

export function getBranch(id) {
    return async () => {
        await request().get(SUCURSALES.OBTENER(id));
    };
}
