export const HOME = '/';
export const PUBLIC = '/';
export const LOGIN = '/login';
export const PRIVATE = '/';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';

export const CONVERSACIONES = '/conversaciones/:origin?/:chatId?';
export const DASHBOARD = '/dashboard';
export const ANALYTICS = '/analytics';
export const CONTACTOS = '/contactos';
export const AGENTES = '/agentes';
export const SUCURSALES = '/sucursales';
export const NOTIFICACIONES = '/notificaciones';
export const INTEGRACIONES = '/integraciones';
export const BOT = '/bot';
export const USUARIOS = '/usuarios';
export const ROLES = '/roles';
export const EMPRESAS = '/empresas';
export const ESTADOS = '/estados';
export const CIUDADES = '/ciudades';
export const PERMISOS = '/permisos';
