// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import request from 'utils/request';
import { ESTADOS } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    states: [],
    town: null
};

const slice = createSlice({
    name: 'states',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getStatesSuccess(state, action) {
            state.states = action.payload;
        },
        
        // GET CONTACT
        getStateSuccess(state, action) {
            state.town = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStates() {
    return async () => {
        const response = await request().get(ESTADOS.LISTAR);
        dispatch(slice.actions.getStatesSuccess(response.data.data));
    };
}

export function saveState(town) {
    return async () => {
        await request().post(ESTADOS.GUARDAR, town);
    };
}

export function updateState(id, town) {
    return async () => {
        await request().put(ESTADOS.ACTUALIZAR(id), town);
    };
}

export function deleteState(id) {
    return async () => {
        await request().delete(ESTADOS.ELIMINAR(id));
    };
}

export function getState(id) {
    return async () => {
        await request().get(ESTADOS.OBTENER(id));
    };
}
