import request from 'utils/request';
import { USUARIOS } from 'config/api/routes';
import validator from 'validator';

export const searchUsers = () => request().get(USUARIOS.LISTAR).then((response) => response.data );

export const saveUser = (data) => request().post(USUARIOS.GUARDAR, data);

export const updateUser = (id, data) => request().put(USUARIOS.ACTUALIZAR(id), data);

export const deleteUser = (id) => request().delete(USUARIOS.ACTUALIZAR(id));

export const updatePasswordUser = (id, data) => request().put(USUARIOS.ACTUALIZAR_CONTRASENIA(id), data);
export const updateBranchesUser = (id, data) => request().put(USUARIOS.ACTUALIZAR_SUCURSALES(id), data);

export const blockUser = (id) => request().put(USUARIOS.BLOQUEAR(id));

export const unblockUser = (id) => request().put(USUARIOS.DESBLOQUEAR(id));

export const validateSave = (fullname= '', username= '', email= '', password= '', role= '') => {
  if(validator.isEmpty(fullname))
    return { message: 'Nombre completo no debe ser vacío', success: false };
  if(validator.isEmpty(username))
    return { message: 'Usuario no debe ser vacío', success: false };
  if(validator.isEmpty(email)) 
    return { message: 'Correo electrónico no debe ser vacío', success: false };
  if(!validator.isEmail(email)) 
    return { message: 'Correo electrónico debe ser un correo electrónico valido', success: false };
  if(validator.isEmpty(password))
    return { message: 'Contraseña no debe ser vacío', success: false };
  if(validator.isEmpty(role))
    return { message: 'Rol no debe ser vacío', success: false };
  return { success: true };
}

export const validateUpdate = (fullname= '', username= '', email= '', role= '') => {
  if(validator.isEmpty(fullname))
    return { message: 'Nombre completo no debe ser vacío', success: false };
  if(validator.isEmpty(username))
    return { message: 'Usuario no debe ser vacío', success: false };
  if(validator.isEmpty(email))
    return { message: 'Correo electrónico no debe ser vacío', success: false };
  if(!validator.isEmail(email)) 
    return { message: 'Correo electrónico debe ser un correo electrónico valido', success: false };
  if(validator.isEmpty(role))
    return { message: 'Rol no debe ser vacío', success: false };
  return { success: true };
}

export const validateUpdatePassword = (password= '', repassword= '') => {
  if(validator.isEmpty(password))
    return { message: 'Contraseña no debe ser vacío', success: false };
  if(validator.isEmpty(repassword))
    return { message: 'Contraseña no debe ser vacío', success: false };
  if(!validator.equals(password, repassword))
    return { message: 'Confirmacion de contraseña debe ser igual a contraseña', success: false };
  return { success: true };
}

export const validateUpdateBranches = (branches= []) => {
  for (let i = 0; i < branches.length; i++) {
    const branch = branches[i];
    if(validator.isEmpty(branch))
      return { message: 'Sucursal no debe ser vacío', success: false };
  }
  return { success: true };
}