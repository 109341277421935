// third-party
import { createSlice } from '@reduxjs/toolkit';
// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import { searchUsers } from "../../views/Usuarios/services/usuarios.services";
import { 
    deleteKanbansColumn, 
    deleteKanbansItem, 
    saveKanbansColumn, 
    saveKanbansItem, 
    saveKanbansItemComment, 
    searchKanbans, 
    searchKanbansColumns, 
    searchKanbansComments, 
    searchKanbansItems, 
    updateKanbansColumn, 
    updateKanbansItem, 
    updateKanbansItemOrder, 
    updateKanbansOrderColumns 
} from 'views/Kanban/services/kanban.services';
// ----------------------------------------------------------------------

const initialState = {
    error: null,
    lockDragAndDrop: false,
    kanbans: [
      
    ],
    columns: [
      
    ],
    columnsOrder: [
    
    ],
    comments: [
       
    ],
    items: [
       
    ],
    profiles: [
       
    ],
    selectedItem: false,
    userStory: [
       
    ],
    userStoryOrder: [
       
    ]
};

const slice = createSlice({
    name: 'kanban',
    initialState,
    reducers: {
        getKanbansSuccess(state, action) {
            state.kanbans = action.payload;
        },
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // ADD COLUMN
        addColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // EDIT COLUMN
        editColumnSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // UPDATE COLUMN ORDER
        updateColumnOrderSuccess(state, action) {
            state.columnsOrder = action.payload.columnsOrder;
        },

        // DELETE COLUMN
        deleteColumnSuccess(state, action) {
            state.columns = action.payload.columns;
            state.columnsOrder = action.payload.columnsOrder;
        },

        // ADD ITEM
        addItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // EDIT ITEM
        editItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // UPDATE COLUMN ITEM ORDER
        updateColumnItemOrderSuccess(state, action) {
            state.columns = action.payload.columns;
        },

        // SELECT ITEM
        selectItemSuccess(state, action) {
            state.selectedItem = action.payload.selectedItem;
        },

        // ADD ITEM COMMENT
        addItemCommentSuccess(state, action) {
            state.items = action.payload.items;
            state.comments = action.payload.comments;
        },

        // DELETE ITEM
        deleteItemSuccess(state, action) {
            state.items = action.payload.items;
            state.columns = action.payload.columns;
            state.userStory = action.payload.userStory;
        },

        // ADD STORY
        addStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // EDIT STORY
        editStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // UPDATE STORY ORDER
        updateStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // UPDATE STORY ITEM ORDER
        updateStoryItemOrderSuccess(state, action) {
            state.userStory = action.payload.userStory;
        },

        // ADD STORY COMMENT
        addStoryCommentSuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.comments = action.payload.comments;
        },

        // DELETE STORY
        deleteStorySuccess(state, action) {
            state.userStory = action.payload.userStory;
            state.userStoryOrder = action.payload.userStoryOrder;
        },

        // GET COLUMNS
        getColumnsSuccess(state, action) {
            state.columns = action.payload;
        },
        // LOCK THE KANBAN TO PREVENT EDITS
        lockKanbanDragAndDrop(state, action) {
            state.lockDragAndDrop = action.payload
        },

        // GET COLUMNS ORDER
        getColumnsOrderSuccess(state, action) {
            state.columnsOrder = action.payload;
        },

        // GET COMMENTS
        getCommentsSuccess(state, action) {
            state.comments = action.payload;
        },

        // GET PROFILES
        getProfilesSuccess(state, action) {
            state.profiles = action.payload;
        },

        // GET ITEMS
        getItemsSuccess(state, action) {
            state.items = action.payload;
        },

        // GET USER STORY
        getUserStorySuccess(state, action) {
            state.userStory = action.payload;
        },

        // GET USER STORY ORDER
        getUserStoryOrderSuccess(state, action) {
            state.userStoryOrder = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------



export function getKanbans() {
    return async () => {
        try {
            const response = await searchKanbans();
            dispatch(slice.actions.getKanbansSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumns(kanbanId) {
    return async () => {
        try {
            const response = await searchKanbansColumns(kanbanId);
            dispatch(slice.actions.getColumnsSuccess(response.data.map(x => ({
                id: x._id,
                itemIds: x.items.map(x => x._id),
                title: x.title
            }))));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getColumnsOrder(kanbanId) {
    return async () => {
        try {
            const response = await searchKanbansColumns(kanbanId);
            const datos = response.data.map(x => x._id).sort((a, b) => a - b);
            dispatch(slice.actions.getColumnsOrderSuccess(datos));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getComments(kanbanId) {
    return async () => {
        try {
            const response = await searchKanbansComments(kanbanId);
            dispatch(slice.actions.getCommentsSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getProfiles(kanbanId) {
    return async () => {
        try {
            const response = await searchUsers();
            dispatch(slice.actions.getProfilesSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getItems(kanbanId, userId, puedeVerTodos) {
    return async () => {
        try {
            console.log('getItems', kanbanId, userId, puedeVerTodos);
            const response = await searchKanbansItems(kanbanId);
            dispatch(
                slice.actions.getItemsSuccess(
                    response.data
                    .filter(x => puedeVerTodos || x.assign?._id === userId)
                    .map(x => ({
                        id: x._id,
                        _id: x._id,
                        title: x.title,
                        amount: x.amount,
                        description: x.description,
                        dueDate: x.dueDate,
                        image: x.image || '',
                        priority: x.priority,
                        order: x.order,
                        attachments: [],
                        commentIds: x.comments,
                        assign: x.assign
                    }))
                )
            );
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserStory(kanbanId) {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/userstory');
            dispatch(slice.actions.getUserStorySuccess(response.data.userStory));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUserStoryOrder(kanbanId) {
    return async () => {
        try {
            const response = await axios.get('/api/kanban/userstory-order');
            dispatch(slice.actions.getUserStoryOrderSuccess(response.data.userStoryOrder));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addColumn(kanbanId, column, columns, columnsOrder) {
    return async () => {
        try {
             // Bloquear el kanban
             dispatch(slice.actions.lockKanbanDragAndDrop(true));
             // Agregar las columnas inmediatamente.
            const response = await saveKanbansColumn(kanbanId,column);
            const nuevo = response.data.data;
            dispatch(slice.actions.addColumnSuccess({
                columns: [...columns, {
                    id: nuevo._id,
                    itemIds: [],
                    title: nuevo.title
                }],
                columnsOrder: [...columnsOrder, nuevo._id]
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
        finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}

export function editColumn(kanbanId, column, columns) {
    return async () => {
        try {
            const response = await updateKanbansColumn(kanbanId, column._id, column);
            const editado = response.data.data;
            dispatch(slice.actions.editColumnSuccess({
                columns: [...columns.map(x => {
                    if(x.id === editado._id){
                        return {
                            id: editado._id,
                            itemIds: [],
                            title: editado.title
                        };
                    }
                    return x;
                })]
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteColumn(kanbanId, columnId, columnsOrder, columns) {
    return async () => {
        try {
            // Bloquear el kanban
            dispatch(slice.actions.lockKanbanDragAndDrop(true));
            // Eliminar las columnas inmediatamente.
            const newColumnsOrder = columnsOrder.filter(x => x !== columnId);
            const newColumns = columns.filter(x => x.id !== columnId);
            dispatch(slice.actions.deleteColumnSuccess({
                columns: newColumns,
                columnsOrder: newColumnsOrder
            }));
            await deleteKanbansColumn(kanbanId, columnId);
            // Usar el orden del server (deberia ser el mismo, pero sirve que se actualiza correctamente si alguien mas cambio algo)
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Si ocurre un error, regresar las columnas a su orden original.
            dispatch(slice.actions.deleteColumnSuccess({
                columns,
                columnsOrder
            }));
        }
        finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}

export function updateColumnOrder({
  kanbanId,
  originColumn,
  destinationColumn,
  newColumnsOrder,
  originalColumnsOrder
}) {
    return async () => {
        try {
            // Bloquear el kanban
            dispatch(slice.actions.lockKanbanDragAndDrop(true));
            // Actualizar las columnas inmediatamente.
            dispatch(slice.actions.getColumnsOrderSuccess(newColumnsOrder))
            await updateKanbansOrderColumns(kanbanId, originColumn, destinationColumn);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Si ocurre un error, regresar las columnas a su orden original.
            dispatch(slice.actions.getColumnsOrderSuccess(originalColumnsOrder));
        }
        finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}


export function addItem(kanbanId, columnId, columns, item, items, storyId, userStory) {
    return async () => {
        try {
            // Bloquear el kanban
            dispatch(slice.actions.lockKanbanDragAndDrop(true));
            // Crear el nuevo item
           
            const response = await saveKanbansItem(kanbanId,{
                title: item.title,
                amount: item.amount,
                column: columnId,
                assign: item.assign,
            });
               
            const newItem = {
                id: response.data.data._id,
                attachments: [],
                commentIds: [],
                description: "",
                dueDate: new Date(),
                image: "",
                priority: "low",
                title: item.title,
                amount: item.amount,
                assign: response.data.assign,
            };
            // Agregar el nuevo item a la columna
            const newItems = [...items, newItem ];
            const newColumns = [...columns.map(c => {
                if(c.id === columnId) {
                    return {
                        ...c,
                        itemIds: [...c.itemIds, newItem.id]
                    }
                }
                return  c;
            })];
            // Actualizar los items .
            dispatch(slice.actions.addItemSuccess({
                items: newItems,
                columns: newColumns,
                userStory
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}

export function editItem(kanbanId, columnId, columns, item, items, storyId, userStory) {
    return async () => {
        try {
            const response = await updateKanbansItem(kanbanId,item._id, item);
            dispatch(slice.actions.editItemSuccess({
                  items: items.map(x => x._id == response.data.data._id ? ({
                    id: response.data.data._id,
                    _id: response.data.data._id,
                    title: response.data.data.title,
                    amount: response.data.data.amount,
                    description: response.data.data.description,
                    dueDate: response.data.data.dueDate,
                    image: response.data.data.image || '',
                    priority: response.data.data.priority,
                    order: response.data.data.order,
                    attachments: [],
                    commentIds: response.data.data.comments,
                    assign: response.data.data.assign
                }): x),
                  columns: columns,
                  userStory: userStory
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateColumnItemOrder(kanbanId, columns, originalColumns) {
    return async () => {
        try {
            dispatch(slice.actions.lockKanbanDragAndDrop(true));
            // Actualizar las columnas inmediatamente.
            dispatch(slice.actions.updateColumnItemOrderSuccess({columns: columns}))
            const response = await updateKanbansItemOrder(kanbanId, columns);
            // Usar el orden del server(deberia ser el mismo, pero sirve que se actualiza correctamente si alguien mas cambio algo)
            dispatch(slice.actions.updateColumnItemOrderSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
            // Si ocurre un error, regresar las columnas a su orden original.
            dispatch(slice.actions.updateColumnItemOrderSuccess({columns: originalColumns}));
        }
        finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}

export function selectItem(selectedItem) {
    return async () => {
        dispatch(slice.actions.selectItemSuccess({selectedItem: selectedItem}));
    };
}

export function addItemComment(kanbanId, itemId, comment, items, comments) {
    return async () => {
        try {
            dispatch(slice.actions.lockKanbanDragAndDrop(true));
            // Actualizar las columnas inmediatamente.
            const response = await saveKanbansItemComment(kanbanId, itemId, comment);
            const newComment = response.data.data;
            const newItems = items.map(x => x.id === itemId ? ({ ...x, commentIds: [...x.commentIds, newComment._id]}) : x);
            const newComments = [...comments, newComment];
            dispatch(slice.actions.addItemCommentSuccess({
                items: newItems, 
                comments: newComments
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}

export function deleteItem(kanbanId, itemId, items, columns, userStory) {
    return async () => {
        try {
            dispatch(slice.actions.lockKanbanDragAndDrop(true));
            // Actualizar las columnas inmediatamente.
            await deleteKanbansItem(kanbanId, itemId);
            const newItems = items.filter( x => x.id !== itemId);
            const newColumns = [ ...columns.map(c => (c.itemIds.indexOf(itemId) > -1 ? ({ ...c, itemIds: c.itemIds.filter( x => x !== itemId)}) : c))];
            dispatch(slice.actions.deleteItemSuccess({
                items: newItems,
                columns: newColumns,
                userStory
            }));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.lockKanbanDragAndDrop(false));
        }
    };
}

export function addStory(kanbanId, story, userStory, userStoryOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-story', { userStory, story, userStoryOrder });
            dispatch(slice.actions.addStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function editStory(kanbanId, story, userStory) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/edit-story', { userStory, story });
            dispatch(slice.actions.editStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStoryOrder(kanbanId, userStoryOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-story-order', { userStoryOrder });
            dispatch(slice.actions.updateStoryOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateStoryItemOrder(kanbanId, userStory) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/update-storyitem-order', { userStory });
            dispatch(slice.actions.updateStoryItemOrderSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addStoryComment(kanbanId, storyId, comment, comments, userStory) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/add-story-comment', { userStory, storyId, comment, comments });
            dispatch(slice.actions.addStoryCommentSuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteStory(kanbanId, storyId, userStory, userStoryOrder) {
    return async () => {
        try {
            const response = await axios.post('/api/kanban/delete-story', { userStory, storyId, userStoryOrder });
            dispatch(slice.actions.deleteStorySuccess(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
