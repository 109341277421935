// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import request from 'utils/request';
import { CONTACTOS } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    contacts: [],
    contact: null
};

const slice = createSlice({
    name: 'contacts',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getContactsSuccess(state, action) {
            state.contacts = action.payload;
        },
        
        // GET CONTACT
        getContactSuccess(state, action) {
            state.contact = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContacts() {
    return async () => {
        const response = await request().get(CONTACTOS.LISTAR);
        dispatch(slice.actions.getContactsSuccess(response.data.data));
    };
}

export function saveContact(contact) {
    return async () => {
        await request().post(CONTACTOS.GUARDAR, contact);
    };
}

export function updateContact(id, contact) {
    return async () => {
        await request().put(CONTACTOS.ACTUALIZAR(id), contact);
    };
}

export function deleteContact(id) {
    return async () => {
        await request().delete(CONTACTOS.ELIMINAR(id));
    };
}

export function getContact(id) {
    return async () => {
        await request().get(CONTACTOS.OBTENER(id));
    };
}
