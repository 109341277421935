export const CONTACTOS = {
  LISTAR: 'contacts',
  GUARDAR: 'contacts',
  OBTENER: (id) => `contacts/${id}`,
  ACTUALIZAR: (id) => `contacts/${id}`,
  ELIMINAR: (id) => `contacts/${id}`,
}


export const USUARIOS = {
  LISTAR: 'users',
  GUARDAR: 'users',
  ACTUALIZAR: (id) => `users/${id}`,
  ELIMINAR: (id) => `users/${id}`,
  ACTUALIZAR_CONTRASENIA: (id) => `users/password/${id}`,
  ACTUALIZAR_SUCURSALES: (id) => `users/branches/${id}`,
  BLOQUEAR: (id) => `users/block/${id}`,
  DESBLOQUEAR: (id) => `users/unblock/${id}`,
}

export const EMPRESAS = {
  LISTAR: 'companies',
  GUARDAR: 'companies',
  ACTUALIZAR: (id) => `companies/${id}`,
  ELIMINAR: (id) => `companies/${id}`,
}

export const SUCURSALES = {
  LISTAR: 'branches',
  GUARDAR: 'branches',
  ACTUALIZAR_USUARIOS: (id) => `branches/users/${id}`,
  ACTUALIZAR: (id) => `branches/${id}`,
  ELIMINAR: (id) => `branches/${id}`,
  ENABLE: (id) => `branches/enable/${id}`,
  DISABLE: (id) => `branches/disable/${id}`,
}

export const ESTADOS = {
  LISTAR: 'states',
  GUARDAR: 'states',
  ACTUALIZAR: (id) => `states/${id}`,
  ELIMINAR: (id) => `states/${id}`,
}

export const CIUDADES = {
  LISTAR: 'towns',
  GUARDAR: 'towns',
  ACTUALIZAR: (id) => `towns/${id}`,
  ELIMINAR: (id) => `towns/${id}`,
}

export const ROLES = {
  LISTAR: 'roles',
  GUARDAR: 'roles',
  ACTUALIZAR: (id) => `roles/${id}`,
  ELIMINAR: (id) => `roles/${id}`,
  ACTUALIZAR_PERMISOS: (id) => `roles/${id}/permissions`,
}

export const FUNCTIONALITIES = {
  LISTAR: 'permissions/functionalities',
  GUARDAR: 'permissions/functionalities',
  ACTUALIZAR: (id) => `permissions/functionalities/${id}`,
  ELIMINAR: (id) => `permissions/functionalities'/${id}`,
}

export const KANBAN = {
  LISTAR: 'kanban',
  OBTENER: (id) => `kanban/${id}`,
  GUARDAR: 'kanban',
  ACTUALIZAR: (id) => `kanban/${id}`,
  ELIMINAR: (id) => `kanban/${id}`,
}

export const KANBAN_COLUMNS = {
  LISTAR: (kanbanId) => `kanban/${kanbanId}/columns`,
  GUARDAR: (kanbanId) => `kanban/${kanbanId}/columns`,
  ACTUALIZAR: (kanbanId, id) => `kanban/${kanbanId}/columns/${id}`,
  ELIMINAR: (kanbanId, id) => `kanban/${kanbanId}/columns/${id}`,
  ACTUALIZAR_ORDEN: (kanbanId, idColumnOne, idColumnTwo) => `kanban/${kanbanId}/columns/orden/${idColumnOne}/${idColumnTwo}`,
}

export const KANBAN_ITEMS = {
  LISTAR: (kanbanId) => `kanban/${kanbanId}/items`,
  GUARDAR: (kanbanId) => `kanban/${kanbanId}/items`,
  ACTUALIZAR: (kanbanId, id) => `kanban/${kanbanId}/items/${id}`,
  ELIMINAR: (kanbanId, id) => `kanban/${kanbanId}/items/${id}`,
  ACTUALIZAR_ORDEN: (kanbanId) => `/kanban/${kanbanId}/update-item-order`,
}

export const KANBAN_COMMENTS = {
  LISTAR: (kanbanId) => `kanban/${kanbanId}/comments`,
}

export const KANBAN_COMMENTS_ITEMS = {
  GUARDAR: (kanbanId, itemId) => `kanban/${kanbanId}/items/comments/${itemId}`,
}