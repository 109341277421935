// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import request from 'utils/request';
import { USUARIOS } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    users: [],
    user: null
};

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getUsersSuccess(state, action) {
            state.users = action.payload;
        },
        
        // GET CONTACT
        getUserSuccess(state, action) {
            state.user = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsers() {
    return async () => {
        try {
            const response = await request().get(USUARIOS.LISTAR);
            dispatch(slice.actions.getUsersSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function saveUser(user) {
    return async () => {
        try {
            await request().post(USUARIOS.GUARDAR, user);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateUser(id, user) {
    return async () => {
        try {
            await request().put(USUARIOS.ACTUALIZAR(id), user);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updatePasswordUser(id, user) {
    return async () => {
        try {
            await request().put(USUARIOS.ACTUALIZAR_CONTRASENIA(id), user);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function deleteUser(id) {
    return async () => {
        try {
            await request().delete(USUARIOS.ELIMINAR(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUser(id) {
    return async () => {
        try {
            await request().get(USUARIOS.OBTENER(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function blockUser(id) {
    return async () => {
        try {
            await request().put(USUARIOS.BLOQUEAR(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function unblockUser(id) {
    return async () => {
        try {
            await request().put(USUARIOS.DESBLOQUEAR(id));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
