import { SOCKET_TIMEOUT } from "config";
export const addServices = (socket) => {

  socket.loadMessagesOf = (chat) => {
    socket.timeout(SOCKET_TIMEOUT).emit('loadMessages', {chatId: chat.chatId, origin: chat.origin})
  }
  
  socket.sendMessageOnChat = ({ chatId, origin}, message) => {
    socket.timeout(SOCKET_TIMEOUT).emit('sendMessage', {chatId, origin, message, type: 'text'})
  }

  socket.markAsRead = (chat) => {
    socket.timeout(SOCKET_TIMEOUT).emit('markAsRead', {chatId: chat.chatId, origin: chat.origin});
  }

  socket.loadMoreMessages = (chat, skip) => {
    socket.timeout(SOCKET_TIMEOUT).emit('loadMoreMessages', {chatId: chat.chatId, origin: chat.origin, skip})
  }

  socket.loadMoreChats = (page) => {
    socket.timeout(SOCKET_TIMEOUT).emit('loadMoreChats', {page: page})
  }

  socket.onCloseChat = (chat) => {
    socket.timeout(SOCKET_TIMEOUT).emit('closeChat', {chatId: chat.chatId, origin: chat.origin})
  }

  return socket;
}
