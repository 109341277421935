import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useAppContext } from '../../context/appContext';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../../components/EnhancedTableHead';
import EnhancedTableToolbar from '../../components/EnhancedTableToolbar';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import Confirmation from '../../components/Confirmation';
import { stableSort } from '../../utils/table';
import Edit from './components/Edit';
import Nuevo from './components/Nuevo';
import { searchTowns, deleteTown } from './services/ciudades.services';
import IfPermission from 'components/IfPermission';

const headCells = [
  {
    id: 'code',
    numeric: false,
    label: 'Código',
  },
  {
    id: 'name',
    numeric: false,
    label: 'Nombre',
  },
  {
    id: 'state',
    numeric: false,
    label: 'Estado',
  },

];

export default function Cuidades() {
  const [rows, setRows] = React.useState([]);
  const { setTitle } = useAppContext();
  React.useEffect(()=>{
    setTitle('Cuidades');
    refresh();
  },[]);
  
  const refresh = ()=>{
    searchTowns().then((response)=>{
      setRows(response.data.map(x => x));
    })
  }
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const [open,setOpen] = React.useState(false);
  const [contactId,setContactId] = React.useState(null);
  const [contact,setContact] = React.useState(null);
  const handleEdit = (row) => {
    setContactId(row._id);
    setContact(row);
    setOpen(true);
  }

  const handleDetail = (e) => {
    e.stopPropagation();
    
  }

  const handleConfirmDelete = (contact) => {
    deleteTown(contact._id).then(() => {
      refresh();
    });
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <EnhancedTableToolbar title="Cuidades">
                <IfPermission functionality="ciudades" action="crear">
                  <Nuevo refresh={refresh} />
                </IfPermission>
              </EnhancedTableToolbar>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  headCells={headCells}
                />
                <TableBody>
                  {stableSort(rows, order, orderBy)
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.code}
                        >
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                          >
                            {row.code}
                          </TableCell>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.state?.name}</TableCell>
                          <TableCell align="right">
                            <IfPermission functionality="ciudades" action="editar">
                              <Tooltip title="Edit">
                                <IconButton color="success" onClick={() => handleEdit(row)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            </IfPermission>
                            <IfPermission functionality="ciudades" action="eliminar">
                              <Confirmation
                                title="Delete"
                                color="error" 
                                message='¿Confirmar que desea eliminar?'
                                icon={<DeleteIcon />}
                                onConfirm={() => handleConfirmDelete(row)}
                              />
                            </IfPermission>
                          </TableCell>
                        </TableRow> 
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={7} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          <Edit open={open} setOpen={(o)=>setOpen(o)} data={contact} id={contactId} refresh={refresh} />
      </Grid>
    </Grid>
  );
}
