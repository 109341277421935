import request from 'utils/request';
import { KANBAN, KANBAN_COLUMNS, KANBAN_COMMENTS_ITEMS, KANBAN_ITEMS, KANBAN_COMMENTS } from 'config/api/routes';
import validator from 'validator';

// Kanban
export const searchKanbans = () => request().get(KANBAN.LISTAR).then((response) => response.data );


// Kanban Columns
export const searchKanbansColumns = (kanbanId) => request().get(KANBAN_COLUMNS.LISTAR(kanbanId)).then((response) => response.data );

export const saveKanbansColumn = (kanbanId, column) => request().post(KANBAN_COLUMNS.GUARDAR(kanbanId), column);

export const updateKanbansColumn = (kanbanId, columnId, column) => request().put(KANBAN_COLUMNS.ACTUALIZAR(kanbanId,columnId), column);

export const deleteKanbansColumn = (kanbanId, columnId) => request().delete(KANBAN_COLUMNS.ELIMINAR(kanbanId, columnId));

export const updateKanbansOrderColumns = (kanbanId, originColumn, destinationColumn) => request().put(KANBAN_COLUMNS.ACTUALIZAR_ORDEN(kanbanId, originColumn, destinationColumn));

// Kanban Comments
export const searchKanbansComments = (kanbanId) => request().get(KANBAN_COMMENTS.LISTAR(kanbanId)).then((response) => response.data );

export const saveKanbansItemComment = (kanbanId, itemId, comment) => request().post(KANBAN_COMMENTS_ITEMS.GUARDAR(kanbanId, itemId), comment);

// Kanban Items
export const searchKanbansItems = (kanbanId) => request().get(KANBAN_ITEMS.LISTAR(kanbanId)).then((response) => response.data );

export const saveKanbansItem = (kanbanId, item) => request().post(KANBAN_ITEMS.GUARDAR(kanbanId), item);

export const updateKanbansItem = (kanbanId, itemId, item) => request().put(KANBAN_ITEMS.ACTUALIZAR(kanbanId, itemId), item);

export const updateKanbansItemOrder = (kanbanId, columns) => request().post(KANBAN_ITEMS.ACTUALIZAR_ORDEN(kanbanId), { columns });

export const deleteKanbansItem = (kanbanId, itemId) => request().delete(KANBAN_ITEMS.ELIMINAR(kanbanId, itemId));
