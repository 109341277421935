import { createContext, useCallback, useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { CRM_APP_TOKEN, CRM_APP_COLLAPSED, CRM_APP_USER, TOKEN } from 'config';

export const AppContext = createContext();

const castUser = (data) => {
    const kanbans = (data.role.kanbans || []).map(x => x._id);
    const functionalities = (data.role.functionalities || []).map(x => ({code: x.code, actions: x.actions }));
    return { 
        username: data.username, 
        sub: data.sub, 
        fullname: data.fullname, 
        role: data.role, 
        kanbans: kanbans,
        functionalities: functionalities,
        kanban: kanbans.length > 0 ? kanbans[0] : null
    };
};

export function AppContextProvider({ children }) {
    const [user, setUser] = useState(JSON.parse(window.localStorage.getItem(CRM_APP_USER)));
    const [isAuthenticated, setAuthenticated] = useState(window.localStorage.getItem(CRM_APP_TOKEN));
    const [isCollapsed, setIsCollapsed] = useState(window.localStorage.getItem(CRM_APP_COLLAPSED) === 'true');
    const [title, setTitle] = useState('');

    const onAuth = useCallback(function({ data }){
        window.localStorage.setItem(CRM_APP_TOKEN, true);
        window.localStorage.setItem(CRM_APP_USER, JSON.stringify(castUser(data)));
        window.localStorage.setItem(TOKEN, data.access_token);
        setAuthenticated(true);
        setUser(castUser(data))
    },[]);

    const collapse = useCallback(function(){
        window.localStorage.setItem(CRM_APP_COLLAPSED,!isCollapsed);
        setIsCollapsed(!isCollapsed);
    },[isCollapsed]);

    const logout = useCallback(function(){
        window.localStorage.removeItem(CRM_APP_TOKEN);
        window.localStorage.removeItem(TOKEN);
        setAuthenticated(false);
    },[]);

    const value = useMemo(
        ()=>({
            onAuth,
            logout,
            isAuthenticated,
            collapse,
            isCollapsed,
            setIsCollapsed,
            title,
            setTitle,
            user,
            setUser
        }),
        [ onAuth, logout, isAuthenticated,collapse, isCollapsed, setIsCollapsed, title, setTitle, user, setUser ]
    );
    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

AppContextProvider.protoTypes = {
    children: PropTypes.object
}

export function useAppContext() {
    return useContext(AppContext);
}