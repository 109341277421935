// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import customerReducer from './slices/customer';
import contactsReducer from './slices/contacts';
import companiesReducer from './slices/companies';
import branchesReducer from './slices/branches';
import statesReducer from './slices/states';
import rolesReducer from './slices/roles';
import usersReducer from './slices/users';
import townsReducer from './slices/towns';
import productReducer from './slices/product';
import chatReducer from './slices/chat';
import calendarReducer from './slices/calendar';
import mailReducer from './slices/mail';
import userReducer from './slices/user';
import cartReducer from './slices/cart';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
import permissionsReducer from './slices/permissions';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    kanban: kanbanReducer,
    customer: customerReducer,
    contacts: contactsReducer,
    companies: companiesReducer,
    product: productReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    states: statesReducer,
    towns: townsReducer,
    users: usersReducer,
    branches: branchesReducer,
    mail: mailReducer,
    user: userReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    menu: menuReducer
});

export default reducer;
