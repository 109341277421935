import socketio from "socket.io-client";
import { SOCKET_URL, TOKEN } from "config";
import { addServices } from './socketServices';
import { addKanbanServices } from './socketKanbanServices';

export const tryConnectToSocket = () => {
  const socket = socketio.connect(SOCKET_URL, {
    extraHeaders: {
      Authorization: `Bearer ${localStorage.getItem(TOKEN)}`
    }
  });
  socket.tryConnect = () => {
    socket.io.opts.extraHeaders.Authorization = `Bearer ${localStorage.getItem(TOKEN)}`;
    socket.connect();
  }
  addServices(socket);
  addKanbanServices(socket);
  return socket;
};

export const defaultSocket = tryConnectToSocket();
