import {SOCKET_TIMEOUT} from "../config";

export const addKanbanServices = (socket) => {
  socket.connectToKanban = (kanbanId) => {
    socket.timeout(SOCKET_TIMEOUT).emit('openKanban', {kanbanId})
  }
  socket.disconnectFromKanban = (kanbanId) => {
    socket.timeout(SOCKET_TIMEOUT).emit('closeKanban', {kanbanId})
  }
  return socket;
}
