import * as React from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Button, IconButton, Dialog, DialogActions, DialogTitle, Tooltip } from '@mui/material';
const MySwal = withReactContent(Swal);

function Confirmation(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose =  () => {
    setOpen(false);
  }
  const onClick = (e) => {
    e.stopPropagation();
    setOpen(true);
    // MySwal.fire({
    //   title: props.message,
    //   // text: "You won't be able to revert this!",
    //   icon: props.type || 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Si, ¡Confirmo!',
    //   cancelButtonText: 'Cancelar'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     props.onConfirm();
    //   }
    // })
  }

  const handleConfirm = (response) => {
    if(response){
      props.onConfirm();
      handleClose();
    } else {
      handleClose();
    }
  }

  return (
    <>
      <Tooltip title={props.title}>
        { props.button ? (
          <Button color={props.color} startIcon={props.icon} fullWidth={props.fullWidth} variant={props.variant} onClick={ onClick }>
            {props.title}
          </Button>
        ):(
          <IconButton color={props.color} fullWidth={props.fullWidth} variant={props.variant} onClick={ onClick }>
          {props.icon}
        </IconButton>
        )}
      </Tooltip>
      <Dialog
          open={open}
          onClose={() => handleClose(false)}
          keepMounted
          maxWidth="xs"
          aria-labelledby="item-delete-title"
          aria-describedby="item-delete-description"
      >
          {open && (
              <>
                  <DialogTitle>{props.message}</DialogTitle>
                  <DialogActions sx={{ mr: 2 }}>
                      <Button onClick={() => handleConfirm(false)} color="error">
                        Cancelar
                      </Button>
                      <Button variant="contained" size="small" onClick={() => handleConfirm(true)} autoFocus>
                        Si, ¡Confirmo!
                      </Button>
                  </DialogActions>
              </>
          )}
      </Dialog>
    </>
  );
}
export default Confirmation; 

Confirmation.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onConfirm: PropTypes.func.isRequired,
};