// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import request from 'utils/request';
import { FUNCTIONALITIES } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    functionalities: [],
    functionality: null
};

const slice = createSlice({
    name: 'permissions',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getFunctionalitiesSuccess(state, action) {
            state.functionalities = action.payload;
        },
        
        // GET CONTACT
        getFunctionalitySuccess(state, action) {
            state.funcionality = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFunctionalities() {
    return async () => {
        const response = await request().get(FUNCTIONALITIES.LISTAR);
        dispatch(slice.actions.getFunctionalitiesSuccess(response.data.data));
    };
}

export function saveFunctionality(funcionality) {
    return async () => {
        await request().post(FUNCTIONALITIES.GUARDAR, funcionality);
    };
}

export function updateFunctionality(id, funcionality) {
    return async () => {
        await request().put(FUNCTIONALITIES.ACTUALIZAR(id), funcionality);
    };
}

export function deleteFunctionality(id) {
    return async () => {
        await request().delete(FUNCTIONALITIES.ELIMINAR(id));
    };
}

export function getFunctionality(id) {
    return async () => {
        await request().get(FUNCTIONALITIES.OBTENER(id));
    };
}
