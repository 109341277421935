const LAYOUT_CONST = {
    VERTICAL_LAYOUT: 'vertical',
    HORIZONTAL_LAYOUT: 'horizontal',
    DEFAULT_DRAWER: 'default',
    MINI_DRAWER: 'mini-drawer'
};


export const Roles = {
    GUEST: 'GUEST',
    ADMIN: 'ADMIN',
    AGENT: 'AGENT',
    MARKETING: 'MARKETING',
    GUARANTEE: 'GUARANTEE',
};

export const ROLES_USERS = [
    { label: 'Invitado', id: 'GUEST' },
    { label: 'Administrador', id: 'ADMIN' },
    { label: 'Agende', id: 'AGENT' },
    { label: 'Marketing', id: 'MARKETING' },
    { label: 'Garantias', id: 'GUARANTEE' },
];
  
export default LAYOUT_CONST;
