import moment from 'moment';
export const NewChat = chat => ({
  ...chat,
  id: chat.chatId,
  name: chat.chatId,// ToDo: Cambiar esto.
  from: chat.chatId,
  isClose: chat.status == 'close',
  unReadChatCount: chat.unreadMessages,
});

export const UpdateStatus = (chat, status) => {
  chat.status = status;
  chat.isClose = chat.status == 'close';
}

export const NewMessage = message => ({
  ...message,
  from: message.chatId,
  text: message.message,
  time: message.timestamp ? moment.unix(message.timestamp).format('h:mm A') : "",
  unixTime: message.timestamp
})