import request from 'utils/request';
import { CIUDADES } from 'config/api/routes';
import validator from 'validator';

export const searchTowns = () => request().get(CIUDADES.LISTAR).then((response) => response.data );

export const saveTown = (data) => request().post(CIUDADES.GUARDAR, data);

export const updateTown = (id, data) => request().put(CIUDADES.ACTUALIZAR(id), data);

export const deleteTown = (id) => request().delete(CIUDADES.ACTUALIZAR(id));

export const validateSave = (code, name, state) => {
  if(validator.isEmpty(code))
    return { message: 'Código no debe ser vacío', success: false };
  if(validator.isEmpty(name))
    return { message: 'Nombre no debe ser vacío', success: false };
  if(validator.isEmpty(state))
    return { message: 'Estado no debe ser vacío', success: false };
  return { success: true };
}

export const validateUpdate = (code, name, state) => {
  if(validator.isEmpty(code))
    return { message: 'Código no debe ser vacío', success: false };
  if(validator.isEmpty(name))
    return { message: 'Nombre no debe ser vacío', success: false };
  if(validator.isEmpty(state))
    return { message: 'Estado no debe ser vacío', success: false };
  return { success: true };
}