import dashboard from './dashboard';
import application from './application';
import forms from './forms';
import elements from './elements';
import samplePage from './sample-page';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [
        dashboard, 
        application, 
        // forms, 
        // elements, 
        // samplePage, 
        // pages, 
        // utilities, 
        // support, 
        // other
    ]
};

export default menuItems;
