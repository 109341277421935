// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import request from 'utils/request';
import { CIUDADES } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    towns: [],
    town: null
};

const slice = createSlice({
    name: 'towns',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET CONTACTS
        getTownsSuccess(state, action) {
            state.towns = action.payload;
        },
        
        // GET CONTACT
        getTownSuccess(state, action) {
            state.town = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTowns() {
    return async () => {
        const response = await request().get(CIUDADES.LISTAR);
        dispatch(slice.actions.getTownsSuccess(response.data.data));
    };
}

export function saveTown(town) {
    return async () => {
        await request().post(CIUDADES.GUARDAR, town);
    };
}

export function updateTown(id, town) {
    return async () => {
        await request().put(CIUDADES.ACTUALIZAR(id), town);
    };
}

export function deleteTown(id) {
    return async () => {
        await request().delete(CIUDADES.ELIMINAR(id));
    };
}

export function getTown(id) {
    return async () => {
        await request().get(CIUDADES.OBTENER(id));
    };
}
