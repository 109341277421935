export const IS_PRODUCTION = process.env.NODE_ENV === 'production'; 
export const IS_DEVELOPMENT = process.env.NODE_ENV === 'development'; 
export const SOCKET_URL = IS_DEVELOPMENT ? "http://localhost:3010":"https://api.comunicatefc.com";
export const API_URL = IS_DEVELOPMENT ? "http://localhost:3010":"https://api.comunicatefc.com";
export const APP_URL = IS_DEVELOPMENT ? "http://localhost:3000":"https://api.comunicatefc.com";
export const SOCKET_TIMEOUT = 5000;
export const TOKEN = 'token';// Ubicacion del token en el localstorage.
export const CRM_APP_TOKEN = 'CRM_APP_TOKEN';
export const CRM_APP_USER = 'CRM_APP_USER';
export const CRM_APP_COLLAPSED = 'CRM_APP_COLLAPSED';
export const PUBLIC_VAPID_KEY = "BNKcilSfaOt9VE1OD5cNyCFEeDHXoNzUBSB2e0iG4nOM29yBq5UnPD2n9vRXbb8CaKD4BsETa_NItNKakSUmJGc";
export const SERVICE_WORKER_ROUTE = '/sw.js';
export const APP_VERSION = '1.0.0';
export const MESSAGES_PER_PAGE = 15;
