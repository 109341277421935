export const formatPhone = (n) => {
  if (n.length == 13) {
      let result = "+";
      result += n.substring(0, 3) + " ";
      result += n.substring(3, 6) + " ";
      result += n.substring(6, 9) + " ";
      result += n.substring(9, 13) + " ";
      return result;
  }
  return n;
}