// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';
import request from 'utils/request';
import { EMPRESAS } from 'config/api/routes';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    companies: [],
    company: null
};

const slice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET COMPANIES
        getCompaniesSuccess(state, action) {
            state.companies = action.payload;
        },
        
        // GET COMPANY
        getCompanySuccess(state, action) {
            state.company = action.payload;
        },
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCompanies() {
    return async () => {
        const response = await request().get(EMPRESAS.LISTAR);
        dispatch(slice.actions.getCompaniesSuccess(response.data.data));
    };
}

export function saveCompany(company) {
    return async () => {
        await request().post(EMPRESAS.GUARDAR, company);
    };
}

export function updateCompany(id, company) {
    return async () => {
        await request().put(EMPRESAS.ACTUALIZAR(id), company);
    };
}

export function deleteCompany(id) {
    return async () => {
        await request().delete(EMPRESAS.ELIMINAR(id));
    };
}

export function getCompany(id) {
    return async () => {
        await request().get(EMPRESAS.OBTENER(id));
    };
}
